import * as React from "react";
import NoDetectedIntent from "../blockchain-intent/no-detected-intent/no-detected-intent";
import LoadingState from "../../ui-library/loading-state/loading-state";
import PageContainer from "../../ui-library/page-container/page-container";
import { SPECIAL_INTENTS } from "../../../constants/special-intents";
import { useQueryDetectBlockchainIntent } from "../../../hooks/live-queries/use-query-detect-intent/use-query-detect-intent";
import BlockchainIntent from "../blockchain-intent/blockchain-intent";

interface DetectBlockchainIntentProps {
  term: string;
}

const DetectBlockchainIntent: React.FC<DetectBlockchainIntentProps> = ({
  term,
}) => {
  const {
    loading,
    intentName,
    confidence,
    // TODO: parameters,
  } = useQueryDetectBlockchainIntent(term);

  if (loading) {
    return (
      <>
        {/* <Navigation topic={Topic.Blockchain} /> */}
        <PageContainer>
          <LoadingState />
        </PageContainer>
      </>
    );
  }

  if (!intentName || intentName.trim() === "" || !confidence) {
    return (
      <>
        {/* <Navigation topic={Topic.Blockchain} /> */}
        <PageContainer>
          <NoDetectedIntent />
        </PageContainer>
      </>
    );
  }

  const matchingIntentKey = Object.keys(SPECIAL_INTENTS).find((k) => {
    return SPECIAL_INTENTS[k].name === intentName;
  });

  return (
    <>
      {/* <Navigation
        topic={Topic.Blockchain}
        activeItem={
          matchingIntentKey ? SPECIAL_INTENTS[matchingIntentKey].nav : undefined
        }
      /> */}

      <PageContainer>
        <BlockchainIntent
          term={term}
          intentName={intentName}
          intentDetectionConfidence={confidence}
        />
      </PageContainer>
    </>
  );
};

export default DetectBlockchainIntent;
