import * as React from "react";
import { PageProps } from "gatsby";
import * as queryString from "query-string";

import Layout from "../../components/layout";
import SEO from "../../components/seo/seo";
import { LogoBunny } from "../../components/site/header/header";
import { Topic } from "../../types/topics";
import DetectBlockchainIntent from "../../components/blockchain/detect-blockchain-intent/detect-blockchain-intent";
import socialImage from "../../images/ask-about-social-blockchain.jpg";

// interface BlockchainAskPageProps {}
type BlockchainAskPageProps = PageProps<null, null>;

const BlockchainAskPage: React.FC<BlockchainAskPageProps> = ({ location }) => {
  // @ts-ignore no term
  const term: string = location?.state?.term;
  const search = queryString.parse(location?.search);
  const termFromQuery = search?.ask as string | undefined;

  return (
    <Layout
      topic={Topic.Blockchain}
      description="digital economy"
      headerUrl="/blockchain/"
      logoBunny={LogoBunny.Main}
      term={term}
    >
      <SEO
        title="Ask a blockchain question"
        description="Ask a question around blockchain technology and we’ll show you an answer"
        imageUrl={socialImage}
      />

      <DetectBlockchainIntent term={term || termFromQuery || ""} />
    </Layout>
  );
};

export default BlockchainAskPage;
